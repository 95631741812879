<template>
  <app-collapse accordion type="border">
    <b-tab title="Campaign">
      <Buttons
        v-bind="{ ...campaignDropdown.mediaPlan }"
        @upsellSuccess="(isSuccess) => upsell_success = isSuccess"
        @importSuccess="(isSuccess) => import_success = isSuccess"
        @autoSyncSuccess="() => autosync_success = !autosync_success"
      />
      <MediaPlan
        :upsell-success="upsell_success"
        :import-success="import_success"
        :tab-index="tabIndex"
        v-bind="{ ...campaignDropdown.mediaPlan }"
      />
      <CampaignOverview
        :auto-sync-success="autosync_success"
        v-bind="{ ...campaignDropdown.campaignOverview }"
      />
      <OpportunityHistory />
    </b-tab>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import axiosInstance from '@/libs/axiosInstance'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import CampaignOverview from './Campaign/CampaignOverview.vue'
import Buttons from './Campaign/Buttons.vue'
import MediaPlan from './Campaign/MediaPlan.vue'
import OpportunityHistory from './Campaign/OpportunityHistory.vue'

export default {
  components: {
    AppCollapse,
    Buttons,
    CampaignOverview,
    MediaPlan,
    OpportunityHistory,
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      upsell_success: false,
      import_success: false,
      autosync_success: false,
      campaignDropdown: {
        mediaPlan: {
          kpiUnitOptions: [],
        },
        campaignOverview: {
          campaignStatusOptions: [],
          objectiveOptions: [],
          landingPageTypeOptions: [],
          languageOptions: [],
          reportSystemOptions: [],
          userLevelPointOptions: [],
          adPlatformOptions: [],

          /* user options */
          amOptions: [],
          optimizerOptions: [],
          copyWriterOptions: [],
          designerOptions: [],
          systemSupportOptions: [],
          dataAnalystOptions: [],
          influencerOptions: [],
          digitalAssociateOptions: [],
          saleOptions: [],
          viewerOptions: [],
        },
      },
    }
  },
  mounted() {
    this.getCampaignDropdown()
  },
  methods: {
    async getCampaignDropdown() {
      try {
        const slugs = 'landing_page_types,campaign_status,objectives,report_systems,languages,users,user_level_points,ad_platform,kpi_units'
        const { data: { data } } = await axiosInstance.get(`dropdown-options/campaign-dropdown-options?slugs=${slugs}`)
        this.campaignDropdown = {
          ...this.campaignDropdown,
          mediaPlan: {
            kpiUnitOptions: data.kpi_units,
          },
          campaignOverview: {
            campaignStatusOptions: data.campaign_status,
            objectiveOptions: data.objectives,
            landingPageTypeOptions: data.landing_page_types,
            languageOptions: data.languages,
            reportSystemOptions: data.report_systems,
            userLevelPointOptions: data.user_level_points.map(userLevelPoint => ({ ...userLevelPoint, text: userLevelPoint.text.toString() })),
            adPlatformOptions: data.ad_platform,

            /* user options */
            amOptions: data.am,
            optimizerOptions: data.optimizer,
            copyWriterOptions: data.copy_writer,
            designerOptions: data.designer,
            systemSupportOptions: data.system_support,
            dataAnalystOptions: data.data_analyst,
            influencerOptions: data.influencer,
            digitalAssociateOptions: data.digital_associate,
            saleOptions: data.sale,
            viewerOptions: data.viewers,
          },
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },
  },
}
</script>
