<template>
  <b-row class="mx-1">
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="campaign-name"
        :value="campaign.name"
        label="Campaign Name"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="campaign-id"
        :value="campaign.campaign_id"
        label="Campaign ID"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="campaign-id"
        :value="campaign.alpha_campaign_id"
        label="Heroai Campaign ID"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="status"
        :value="campaign.campaign_status_name"
        label=" Campaign Status"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="business_objectives"
        :value="campaign.business_objectives_name"
        label="Business Objectives"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="brief-date"
        :value="getDate(campaign.brief_date)"
        label="Brief Date"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="start-date"
        :value="getDate(campaign.actual_campaign_start_date)"
        label="Campaign Start Date"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="end-date"
        :value="getDate(campaign.actual_campaign_end_date)"
        label="Campaign End Date"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="pause-date"
        :value="getDate(campaign.paused_date)"
        label="Campaign Pause Date"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="unpause-date"
        :value="getDate(campaign.unpaused_date)"
        label="Campaign Restart Date"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="churn-date"
        :value="getDate(campaign.churn_date)"
        label="Churn Date"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="churn-cycle"
        :value="campaign.churn_cycle"
        label="Churn Cycle"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="google-account-id"
        v-b-tooltip.hover.v-primary
        :title="campaign.google_account_id.join(', ')"
        :value="campaign.google_account_id.join(', ')"
        label="Google Account ID"
        custom-class="text-truncate"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="facebook-account-id"
        v-b-tooltip.hover.v-primary
        :title="campaign.facebook_account_id.join(', ')"
        :value="campaign.facebook_account_id.join(', ')"
        label="Facebook Account ID"
        custom-class="text-truncate"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="tiktok-account-id"
        v-b-tooltip.hover.v-primary
        :title="campaign.tiktok_account_id.join(', ')"
        :value="campaign.tiktok_account_id.join(', ')"
        label="TikTok Account ID"
        custom-class="text-truncate"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="buffer"
        :value="campaign.buffer.toString()"
        label="Buffer"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="bonus"
        :value="campaign.bonus"
        label="Bonus/Discount"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="ga-property-id"
        :value="campaign.ga_property_id"
        label="GA Property ID"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="ga-profile-id"
        :value="campaign.ga_profile_id"
        label="GA Profile ID"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="client-website"
        :value="campaign.client_website"
        label="Client Website"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="client_at_risk_stage"
        :value="campaign.client_at_risk_stage"
        label="Client at risk stage"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="reason_for_high_risk"
        :value="campaign.reason_for_high_risk"
        label="Reason for high risk"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="hashtags"
        :value="campaign.hashtags.join(', ')"
        label="Hashtags"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="demo-campaign"
        :value="campaign.demo_campaign ? 'Yes' : 'No'"
        label="Demo Campaign"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="is-no-sync-campaign"
        :value="campaign.is_no_sync_campaign ? 'Yes' : 'No'"
        label="No Sync Campaign"
      />
    </b-col>
  </b-row>
</template>

<script>
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import BreadCrumbMixin from '@/mixins/breadcrumb'
import DataFormatService from '@/services/DataFormatService'

export default {
  name: 'CampaignOverview',
  components: {
    HeroDisplayText,
  },
  mixins: [BreadCrumbMixin],
  props: {
    campaign: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  mounted() {
    this.setBreadCrumb(this.campaign.name)
  },
  methods: {
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
  },
}
</script>
